<template>
  <div
    class="container noColor"
    v-title
    :data-title="$t('i18n.groupManagement')"
    id="groupManagement"
  >
    <h5>{{ $t("i18n.groupLevel") }}</h5>
    <div class="flex">
      <el-tree
        ref="tree"
        highlight-current
        node-key="id"
        @node-click="nodeClick"
        :props="props1"
        :load="loadNode1"
        lazy
        :expand-on-click-node="false"
      ></el-tree>
      <div class="w100p">
        <el-card class="box-card">
          <template #header>
            <span>{{ $t("i18n.basicInfo") }}</span>
            <el-button class="fr" type="text" @click="$router.go(-1)">
              {{ $t("i18n.back") }}
            </el-button>
            <el-button
              v-has="'编辑小组'"
              class="fr"
              type="text"
              @click="setIsEdit(false)"
            >
              {{ $t("i18n.edit") }}
            </el-button>
          </template>
          <div class="text item">
            <jl-form
              :columns="basicColumns"
              @onSubmit="onSubmit"
              @resetForm="setIsEdit(true)"
            ></jl-form>
          </div>
        </el-card>
        <el-card class="box-card m-t">
          <template #header>
            <span>{{ $t("i18n.elevatorList") }}</span>
            <el-button
              class="fr"
              type="text"
              @click="newElevatorVisible = true"
            >
              {{ $t("i18n.addElevator") }}
            </el-button>
          </template>
          <div class="text item">
            <jl-table
              @init="getElevatorList"
              @handleDelete="handleDelete"
              :tableData="elevatorData"
              :options="elevatorOptions"
              :columns="elevatorColumns"
              :operates="elevatorOperates"
              :total="elevatorTotal"
              :height="$defined.HEIGHT - 560 + ''"
            >
            </jl-table>
          </div>
        </el-card>
      </div>
    </div>
    <el-dialog
      :title="$t('i18n.addElevator')"
      v-model="newElevatorVisible"
      :close-on-click-modal="false"
      width="80%"
      destroy-on-close
    >
      <jl-table
        @init="getAddEleList"
        :tableData="addEleData"
        :options="addEleOptions"
        :columns="addEleColumns"
        :operates="addEleOperates"
        :total="addEleTotal"
        @handleSelectionChange="
          (val) => {
            handleSelectionChange(val, 'selectNewData');
          }
        "
        height="500"
      ></jl-table>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import jlForm from "../../components/form";
import jlTable from "../../components/table";
export default {
  name: "BaseviewAllotUpdate",
  components: {
    jlForm,
    jlTable,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();
    const state = reactive({
      paging: {
        page: 1,
        pageSize: 20,
      },
      basicColumns: [
        {
          prop: "name",
          label: "groupName",
          type: "input",
          readonly: true,
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "level",
          label: "groupLevel",
          type: "input",
          readonly: true,
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "parentId",
          label: "parentGroup",
          align: "center",
          show: true,
          rows: false,
          type: "select",
          props: { label: "name", value: "id" },
          data: [],
          rules: [{ required: true, message: t("i18n.input") }],
        },
      ],
      elevatorData: [],
      elevatorTotal: 0,
      elevatorOptions: {
        isDelete: true,
        loading: false, // 是否添加表格loading加载动画
        highlightCurrentRow: true, // 是否支持当前行高亮显示
        multiSelect: false, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      elevatorColumns: [
        {
          prop: "number",
          label: "elevatorNo",
          align: "center",
          search: true,
          type: "input",
          formatter: (row) => {
            return `<a>${row.number}</a>`;
          },
          method: (row) => {
            router.push({
              path: "/elevator/addEditElevator",
              query: { flag: 1, id: row.id },
            });
          },
        },
        {
          prop: "elevatorType",
          label: "elevatorType",
          align: "center",
        },
        {
          prop: "projectName",
          label: "projectName",
          align: "left",
        },
        {
          prop: "address",
          label: "address",
          align: "left",
        },
      ],
      elevatorOperates: {
        width: 100,
        fixed: "right",
        list: [],
      },
      groupDetail: "",
      newElevatorVisible: false, // 新增电梯弹窗
      addEleColumns: [
        {
          prop: "contractNumber",
          label: "maintenanceContractNo",
          align: "center",
          type: "input",
          search: true,
          rows: false,
        },
        {
          prop: "number",
          label: "elevatorNo",
          align: "center",
          search: true,
          type: "input",
        },
        {
          prop: "alias",
          label: "projectAlias",
          align: "left",
        },
        {
          prop: "projectName",
          label: "projectName",
          align: "left",
          width: 300,
          type: "input",
        },
        {
          prop: "elevatorType",
          label: "elevatorType",
          align: "center",
        },
      ],
      addEleOperates: {
        width: 100,
        fixed: "right",
        list: [
          {
            id: "1",
            label: "addElevator",
            icon: "el-icon-plus",
            class: "add",
            show: true,
            type: "outer",
            method: () => {
              addNewElevator();
            },
          },
        ],
      },
      addEleOptions: {
        loading: false, // 是否添加表格loading加载动画
        highlightCurrentRow: true, // 是否支持当前行高亮显示
        multiSelect: true, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      addEleTotal: 0,
      addEleData: [],
      selectNewData: [], // 选中未添加到小组的电梯
      props1: {
        label: "name",
        children: "zones",
        isLeaf: "leaf",
      },
    });

    const init = async () => {
      let id = route.query.id;
      let { data } = await proxy.$api.system.allotDetail(id);
      state.companyId = data.companyId;
      getAllotParent(data.level);

      state.groupDetail = data;
      setIsEdit(true, data);
    };
    const getAllotParent = async (level) => {
      var index = state.basicColumns.findIndex((item) => {
        return item.prop === "parentId";
      });
      let { data } = await proxy.$api.system.getAllotParent({
        companyId: state.companyId,
        level: level,
      });
      state.basicColumns[index].data = data;
      state.basicColumns[index].show = data.length > 0;
    };

    const onSubmit = async (row) => {
      if (state.groupDetail.number != row.number) {
        await proxy.$api.system.isExistContract({ number: row.number });
      }
      editAllot(row);
    };

    const editAllot = async (params) => {
      let data = JSON.parse(JSON.stringify(params));
      if (data.signDate) {
        data.signDate = proxy.$defined.format(data.signDate);
      }
      if (Array.isArray(data.manuscript)) {
        let urls = [];
        data.manuscript.map((item) => {
          urls.push(item.url);
        });
        data.manuscript = urls.join(",");
      }
      data.id = route.query.id;
      await proxy.$api.system.editAllot(data);
      proxy.$defined.tip(t("i18n.edit") + t("i18n.success"), "success");
      init();
    };

    const getElevatorList = async (params) => {
      params.id = route.query.id;
      const { data } = await proxy.$api.system.allotElevator(params);
      state.elevatorData = data.records;
      state.elevatorTotal = data.total;
    };

    const addNewElevator = async () => {
      // 新增电梯到小组
      var ids = checkSelectEle("selectNewData");
      if (ids.length > 0) {
        await proxy.$api.system.addElevatorToAllot({
          allotId: route.query.id,
          elevatorIds: ids,
          total: false,
        });
        proxy.$defined.tip(t("i18n.new") + t("i18n.success"), "success");
        state.newElevatorVisible = false;
        getElevatorList(state.paging);
      }
    };

    const checkSelectEle = (key) => {
      if (state[key].length > 0) {
        return state[key];
      } else {
        proxy.$defined.tip(t("i18n.selectElevators"));
        return "";
      }
    };

    const handleSelectionChange = (val, key) => {
      let elevatorIds = [];
      val.map((ele) => {
        elevatorIds.push(ele.id);
      });
      state[key] = elevatorIds;
    };

    const setIsEdit = (flag, data) => {
      state.basicColumns.map((item, index) => {
        item.readonly = flag;
        item.isUpdate = true;
        if (data && data[item.prop] != null && data[item.prop] != undefined) {
          if (item.type === "upload") {
            let files = [];
            data[item.prop].split(",").map((f) => {
              let name = f.split("/")[f.split("/").length - 1];
              let obj = {
                name: name,
                url: f,
              };
              files.push(obj);
            });
            item.value = files;
          } else {
            item.value = data[item.prop];
            state.basicColumns[index].value = data[item.prop];
          }
        }
      });
    };

    const getAddEleList = async (params) => {
      // 加载未添加的电梯
      params.id = route.query.id;
      params.elevatorNumber = params.number || "";
      const { data } = await proxy.$api.system.allotNotElevator(params);
      state.addEleData = data.records;
      state.addEleTotal = data.total;
    };

    const handleDelete = (row, pages) => {
      let elevatorIds = [];
      elevatorIds.push(row.id);
      proxy.$api.system
        .delElevatorToAllot({
          allotId: route.query.id,
          elevatorIds: elevatorIds,
        })
        .then(() => {
          getElevatorList(pages);
          proxy.$defined.tip(t("i18n.delete") + t("i18n.success"), "success");
        });
    };

    const nodeClick = (obj) => {
      router.push({
        path: "/system/baseviewAllotUpdate",
        query: { id: obj.id, name: obj.name },
      });
    };

    const loadNode1 = async (node, resolve) => {
      if (node.level === 0) {
        return resolve([{ name: route.query.name, id: route.query.id }]);
      } else {
        getAllotChild(node.data.id, function (data) {
          resolve(data);
        });
      }
    };

    const getAllotChild = async (id, callback) => {
      let { data } = await proxy.$api.system.getAllotChild({ id: id });
      let arr = [];
      data.map((item) => {
        let obj = {};
        obj.id = item.id;
        obj.name = item.name;
        arr.push(obj);
      });
      callback(arr);
    };

    init();

    return {
      ...toRefs(state),
      onSubmit,
      getElevatorList,
      handleSelectionChange,
      setIsEdit,
      addNewElevator,
      getAddEleList,
      handleDelete,
      nodeClick,
      loadNode1,
    };
  },
};
</script>

<style lang="scss">
#groupManagement {
  h5 {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    font-size: $font + 4;
    font-weight: normal;
    padding: 18px 20px;
    width: 245px;
    border-bottom: 1px solid #ebeef5;
  }
  .form-button {
    width: 100%;
    margin: 0;
    text-align: center;
  }
  .el-tree {
    padding: 80px 20px 0;
    width: 300px;
    margin-right: 20px;
    .el-tree-node__label {
      font-size: $font + 2;
    }
    .el-tree-node {
      white-space: normal;
    }
    .el-tree-node__content {
      height: auto;
    }
  }
  .w100p {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  #groupManagement {
    margin-top: -20px;
    overflow-x: auto;
  }
}
</style>
